import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import EditSource from '../components/EditSource';
import Layout from '../components/Layout';
import Link from '../components/Link';

import useCorrectLanguage from '../hooks/useCorrectLanguage';

const Error404Page = ({ pageContext: { language }, location: { pathname } }) => {
  const { t } = useTranslation();
  useCorrectLanguage(language);

  const isLexique = Boolean(pathname.match(/^\/lexique/));

  return (
    <Layout translationsAvailable title={t('Page non trouvée')}>
      {Boolean(isLexique) && (
        <>
          <Typography variant="h1" gutterBottom>
            {t('Il n\'y a pas encore de définition pour ce terme')}
          </Typography>

          <Typography variant="body1" paragraph>
            Vous pouvez en proposer une
            directement <EditSource label="sur Gitlab" sourcePath={`src/md-pages${pathname}.md`} />.
          </Typography>
        </>
      )}

      {!isLexique && (
        <Typography variant="h1" gutterBottom>
          {t('La page demandée n\'existe pas')}
        </Typography>
      )}

      <Typography variant="body1" paragraph>
        <Link to="/" prefix>{t('‹ Retourner à la page d\'accueil')}</Link>
      </Typography>
    </Layout>
  );
};

export default Error404Page;
